<script setup lang="ts">
defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["closeDialog"]);

const closeDialog = () => {
  emits("closeDialog");
};
</script>

<template>
  <div>
    <Transition name="fade">
      <div
        v-if="showDialog"
        class="dialog-backdrop inset-0 z-30"
        @click="closeDialog"
      />
    </Transition>
    <Transition name="dialog">
      <div
        v-if="showDialog"
        class="max-w-full w-[350px] p-7 pb-2 fixed z-50 translate-x-[-50%] translate-y-[-50%] top-1/2 left-1/2 focus:outline-none shadow-lg rounded-md bg-white border border-black"
      >
        <div class="font-title text-2xl font-bold text-center mb-4">
          {{ title }}
        </div>

        <slot />
      </div>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.dialog-backdrop {
  @apply w-screen fixed left-0 top-0 z-40 flex items-center justify-center bg-black/40 backdrop-blur-md;
}
</style>
