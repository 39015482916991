<script setup lang="ts">
const navigationMenuStore = useNavigationMenuStore();

const route = useRoute();
const router = useRouter();

const { user, isAuthenticated, logout } = useAuth();

const onLogout = () => {
  logout();
  router.push("/");
};
</script>

<template>
  <div class="flex flex-row items-center space-x-5">
    <nuxt-link
      v-for="item in navigationMenuStore.menuItems"
      :key="item.title"
      :to="item.link"
      :class="{
        'hover:underline underline-offset-[3px] text-black/80 whitespace-nowrap': true,
        underline: route.path === item.link,
      }"
    >
      {{ item.title }}
    </nuxt-link>
    <button
      v-if="isAuthenticated && user?.id"
      class="text-black/80 hover:text-red-800 w-min hover:underline"
      @click="onLogout"
    >
      Abmelden
    </button>
  </div>
</template>
