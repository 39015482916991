export const useNavigationMenuStore = defineStore("navigationMenu", () => {
  const showMenu = useState("showMenu", () => false);

  const toggleMenu = () => {
    showMenu.value = !showMenu.value;
  };

  const { isAuthenticated, user } = useAuth();

  const { Texts } = useTexts();
  const { partner } = useTheme();

  const menuItems = computed(() => {
    return [
      partner.value !== null && {
        title: "Gedenkseiten",
        link: "/gedenkseiten",
      },
      isAuthenticated.value === true && {
        title: "Mein Konto",
        link: "/mein-konto",
      },
      {
        title: "Kontakt",
        link: "/kontakt",
      },
      Texts.value.isC && {
        title: "Shop",
        link: "https://meinseelenspiegel.de",
      },
      partner.value !== null &&
        hasPartnerAccess(partner.value, user.value?.id ?? 0) && {
          title: "Dashboard",
          link: "/partner/dashboard",
        },
    ].filter((item) => item) as Array<{
      title: string;
      link: string;
    }>;
  });

  const legalItems = [
    {
      title: "Datenschutz",
      link: "/datenschutz",
    },
    {
      title: "Impressum",
      link: "/impressum",
    },
    {
      title: "AGBs",
      link: "/agbs",
    },
  ];

  return {
    showMenu,
    toggleMenu,
    menuItems,
    legalItems,
  };
});
