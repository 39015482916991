// Because Godaddy can't manage to redirect the subdomain we have to do it here
export default defineNuxtRouteMiddleware(() => {
  const url = window?.location?.href;

  if (
    url &&
    (url.startsWith("https://flyer.memoratech.com") ||
      url.startsWith("https://www.flyer.memoratech.com"))
  ) {
    return navigateTo("https://www.memoratech.de/flyer", {
      external: true,
      replace: true,
      redirectCode: 301,
    });
  }
});
