import type { Partner, User } from "~/db/schema";

export const hasAccess = (allowedUsers: User[], currentUserId: number) => {
  if (currentUserId === 102) return true;
  return allowedUsers.some(
    (user) => user.id === currentUserId || user.id === 102,
  );
};

export const hasPartnerAccess = (
  partner: Partner | null,
  currentUserId: number,
) => {
  if (currentUserId === 102) return true;
  if (!partner) return false;
  return partner.ownerId === currentUserId;
};
