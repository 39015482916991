<script setup lang="ts">
const checkoutStore = useCheckoutStore();

const { params } = useRoute();

const { data: memorial } = useAsyncData("memorial", async () => {
  return await $fetch(`/api/${params.code}/memorial`);
});

const alreadyExpired = computed(() => {
  if (!memorial.value?.createdAt) return false;

  return (
    new Date(memorial.value.createdAt).getTime() + 1000 * 60 * 60 * 24 * 7 <
    new Date().getTime()
  );
});

const timeLeft = computed(() => {
  if (!memorial.value?.createdAt) return "0 Tage, 0 Stunden";

  const timeLeft = new Date(
    new Date(memorial.value.createdAt).getTime() + 1000 * 60 * 60 * 24 * 7,
  ).getTime();

  const now = new Date().getTime();

  const diff = timeLeft - now;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  if (days > 0) {
    return `${days} ${days === 1 ? "Tag" : "Tagen"}`;
  } else {
    return `${hours} ${hours === 1 ? "Stunde" : "Stunden"}`;
  }
});
</script>

<template>
  <div
    class="relative h-full w-full flex flex-col items-center space-y-10 mb-20"
  >
    <div>
      <p class="text-3xl font-title font-bold my-4 text-center">
        {{ alreadyExpired ? "Dein Test ist vorbei" : "Dein Test endet bald" }}
      </p>
      <p class="text-black font-semibold text-center">
        {{
          alreadyExpired
            ? "Dein Seelenspiegel wurde deaktiviert, weil du die Testphase nicht verlängert hast."
            : "Dein Seelenspiegel wird in " +
              timeLeft +
              " deaktiviert, wenn du ihn nicht verlängerst."
        }}
      </p>

      <p class="text-black/70 text-center mt-5 text-sm">
        {{
          alreadyExpired
            ? "Du kannst deinen Seelenspiegel wieder aktivieren, indem du eine der untenstehenden Optionen wählst."
            : "Du kannst deinen Seelenspiegel verlängern, indem du eine der untenstehenden Optionen wählst."
        }}
      </p>
    </div>

    <div
      class="w-full h-fit pb-10 flex flex-col items-center justify-center space-y-2 max-w-lg"
    >
      <div class="flex flex-row items-center justify-center space-x-4 w-full">
        <div class="flex-grow h-[1.5px] bg-black/20"></div>
        <p class="text-black text-sm my-1 text-center">
          Einmalzahlung für den Seelenspiegel
        </p>
        <div class="flex-grow h-[1.5px] bg-black/20"></div>
      </div>

      <!-- <button
        :class="{
          'bg-primary/60 border-primary shadow-primary/30 scale-[1.025] font-bold':
            checkoutStore.selectedOption === '1year',
          'bg-primary/5 border border-primary/70 transition-all duration-150 active:scale-105 shadow-xl shadow-black/5': true,
        }"
        class="w-full p-5 flex flex-row items-center justify-between rounded-md"
        @click="checkoutStore.selectOption('1year')"
      >
        <div class="flex flex-row items-center justify-center space-x-3">
          <div
            class="w-8 h-8 rounded-full bg-gradient-to-br from-black/5 via-black/0 to-black/5 flex items-center justify-center shadow-lg shadow-black/10 border border-black/5"
          >
            <icon
              name="material-symbols:workspace-premium"
              size="17"
              class="text-black/80"
            />
          </div>
          <div class="flex flex-col items-start">
            <p class="font-medium text-black text-base">1 Jahr</p>
            <p class="font-medium text-black/60 text-xs">2,08€/Monat</p>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center space-x-2">
          <p
            class="font-semibold text-xs text-green-900 bg-green-400/40 px-2 py-1 rounded-full text-center"
          >
            Günstig
          </p>
          <p class="text-base">€24,95</p>
        </div>
      </button> -->
      <button
        :class="{
          'bg-primary/60 border-primary shadow-primary/30 scale-[1.025] font-bold':
            checkoutStore.selectedOption === '10years',
          'bg-primary/5 border border-primary/70 transition-all duration-150 active:scale-105 shadow-xl shadow-black/5': true,
        }"
        class="w-full p-5 flex flex-row items-center justify-between rounded-md"
        @click="checkoutStore.selectOption('10years')"
      >
        <div class="flex flex-row items-center justify-center space-x-3">
          <div
            class="w-8 h-8 rounded-full bg-gradient-to-br from-black/5 via-black/0 to-black/5 flex items-center justify-center shadow-lg shadow-black/10 border border-black/5"
          >
            <icon
              name="material-symbols:workspace-premium"
              size="17"
              class="text-black/80"
            />
          </div>
          <div class="flex flex-col items-start">
            <p class="font-medium text-black text-base">10 Jahre</p>
            <p class="font-medium text-black/60 text-xs">0,66€/Monat</p>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center space-x-2">
          <p
            class="font-semibold text-xs line-through text-red-700 bg-red-400/20 px-2 py-1 rounded-full"
          >
            €99,95
          </p>
          <p class="text-base">€79,95</p>
        </div>
      </button>

      <button
        :class="{
          'bg-primary/60 border-primary shadow-primary/30 scale-[1.025] font-bold':
            checkoutStore.selectedOption === '15years',
          'bg-primary/5 border border-primary/70 transition-all duration-150 active:scale-105 shadow-xl shadow-black/5': true,
        }"
        class="w-full p-5 flex flex-row items-center justify-between rounded-md"
        @click="checkoutStore.selectOption('15years')"
      >
        <div class="flex flex-row items-center justify-center space-x-3">
          <div
            class="w-8 h-8 rounded-full bg-gradient-to-br from-black/5 via-black/0 to-black/5 flex items-center justify-center shadow-lg shadow-black/10 border border-black/5"
          >
            <icon
              name="game-icons:big-diamond-ring"
              size="17"
              class="text-black/80"
            />
          </div>
          <div class="flex flex-col items-start">
            <p class="font-medium text-black text-base">15 Jahre</p>
            <p class="font-medium text-black/60 text-xs">0,55€/Monat</p>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center space-x-2">
          <p
            class="font-semibold text-xs text-green-900 bg-green-400/40 px-2 py-1 rounded-full text-center"
          >
            Bestseller
          </p>
          <p class="text-base">€99,95</p>
        </div>
      </button>

      <button
        :class="{
          'bg-primary/60 border-primary shadow-primary/30 scale-[1.025] font-bold':
            checkoutStore.selectedOption === '25years',
          'bg-primary/5 border border-primary/70 transition-all duration-150 active:scale-105 shadow-xl shadow-black/5': true,
        }"
        class="w-full p-5 flex flex-row items-center justify-between rounded-md"
        @click="checkoutStore.selectOption('25years')"
      >
        <div class="flex flex-row items-center justify-center space-x-3">
          <div
            class="w-8 h-8 rounded-full bg-gradient-to-br from-black/5 via-black/0 to-black/5 flex items-center justify-center shadow-lg shadow-black/10 border border-black/5"
          >
            <icon name="ion:diamond-outline" size="17" class="text-black/80" />
          </div>
          <div class="flex flex-col items-start">
            <p class="font-medium text-black text-base">25 Jahre</p>
            <p class="font-medium text-black/60 text-xs">0,39€/Monat</p>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center space-x-2">
          <p
            class="font-semibold text-xs line-through text-red-700 bg-red-400/20 px-2 py-1 rounded-full"
          >
            €199,95
          </p>
          <p class="text-base">€119,95</p>
        </div>
      </button>

      <!-- <div
        class="flex flex-row items-center justify-center space-x-4 w-full pt-2"
      >
        <div class="flex-grow h-[1.5px] bg-black/20"></div>
        <p class="text-black/80 text-sm my-1 text-center">
          Alternativ per Abo-Modell
        </p>
        <div class="flex-grow h-[1.5px] bg-black/20"></div>
      </div>

      <button
        :class="{
          'bg-primary/60 border-primary shadow-primary/30 scale-[1.025] font-bold':
            checkoutStore.selectedOption === 'subscription',
          'bg-primary/5 border border-primary/70 transition-all duration-150 active:scale-105 shadow-xl shadow-black/5': true,
        }"
        class="w-full p-5 flex flex-row items-center justify-between rounded-md"
        @click="checkoutStore.selectOption('subscription')"
      >
        <div class="flex flex-row items-center justify-center space-x-3">
          <div
            class="w-8 h-8 rounded-full bg-gradient-to-br from-black/5 via-black/0 to-black/5 flex items-center justify-center shadow-lg shadow-black/10 border border-black/5"
          >
            <icon name="ion:timer-outline" size="18" class="text-black/80" />
          </div>
          <div class="flex flex-col items-start">
            <p class="font-medium text-black text-base">Abonnement</p>
            <p class="font-medium text-xs text-black/70">mtl. kündbar</p>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center space-x-2">
          <p class="text-base">€2,95/Monat</p>
        </div>
      </button> -->
    </div>
  </div>
</template>
