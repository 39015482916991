export const useAppBarStore = defineStore("appBar", () => {
  const elevated = useState("elevated", () => true);
  // TODO - profile status banner
  const message = useState("message", () => null);

  const route = useRoute();

  watchEffect(() => {
    if (route.name !== "u-code") {
      elevated.value = true;
    }
  });

  return {
    elevated,
    message,
  };
});
