<script setup lang="ts">
const { params } = useRoute();
const code = params.code as string;

const checkoutStore = useCheckoutStore();

onMounted(() => {
  checkoutStore.loadStripeInstance();
});

const pricingEl = ref<HTMLElement>();

const { apply: applyToPricing } = useMotion(pricingEl, {
  initial: {
    scale: 1,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
  },
});

const upsellsEl = ref<HTMLElement>();

const { apply: applyToUpsells } = useMotion(upsellsEl, {
  initial: {
    scale: 1,
    opacity: 0,
  },
  enter: {
    scale: 0,
    opacity: 0,
  },
});
</script>

<template>
  <div
    class="h-screen w-screen fixed top-0 left-0 z-50 flex flex-col items-center bg-white pt-10"
  >
    <Transition name="fade">
      <div
        v-if="checkoutStore.isLoadingStripe"
        class="w-screen h-screen absolute top-0 left-0 bg-white/40 backdrop-blur-md flex items-center justify-center z-50"
      >
        <icon
          name="svg-spinners:180-ring-with-bg"
          size="45px"
          class="text-primary"
        />
      </div>
    </Transition>
    <div
      class="w-screen flex flex-row items-center justify-between fixed top-0 left-0 p-5 pt-4 pb-4 z-30 bg-white/80 backdrop-blur-sm"
    >
      <button
        v-if="checkoutStore.currentStep !== 'pricing'"
        class="w-8 h-8 flex items-center justify-center rounded-full text-black/70 border border-black/5"
        @click="checkoutStore.onPrevious(applyToPricing, applyToUpsells)"
      >
        <Icon name="ion:chevron-back" size="17" />
      </button>
      <div v-else></div>

      <button
        class="w-8 h-8 flex items-center justify-center rounded-full text-black/70 border border-black/5"
        @click="checkoutStore.onClosePopup"
      >
        <Icon name="material-symbols:close" size="17" />
      </button>
    </div>

    <div
      v-show="checkoutStore.currentStep === 'pricing'"
      ref="pricingEl"
      class="pricing-el"
    >
      <BuyPopupPricingEl />
    </div>
    <div
      v-show="checkoutStore.currentStep === 'upsells'"
      ref="upsellsEl"
      class="upsells-el"
    >
      <BuyPopupUpsellsEl />
    </div>

    <div
      class="h-[91px] p-4 w-full fixed bottom-0 left-0 bg-primary/10 space-y-3 border-t border-primary/60 backdrop-blur-sm"
    >
      <button
        :class="[
          'w-full px-8 py-4 rounded-md bg-gradient-to-br bg-primary/80 border border-primary font-bold shadow-xl active:shadow-primary/60 shadow-primary/40 active:scale-105 transition-all duration-150',
          checkoutStore.currentStep === 'upsells' &&
          !checkoutStore.anyUpsellsSelected
            ? 'brightness-[0.65] active:brightness-100 duration-300'
            : '',
        ]"
        @click="checkoutStore.onNext(applyToPricing, applyToUpsells, code)"
      >
        {{
          checkoutStore.currentStep === "pricing"
            ? "Auswählen"
            : checkoutStore.currentStep === "upsells"
              ? "Zur Kasse"
              : "Zahlen"
        }}
      </button>
    </div>
    <CustomDialog
      title="Bist du dir sicher?"
      :show-dialog="checkoutStore.showConfirmUpsellsDialog"
      @close-dialog="checkoutStore.onCloseConfirmUpsellsDialog"
    >
      <div class="space-y-5 pb-4">
        <p class="text-black text-center text-base">
          Möchtest du wirklich fortfahren, ohne deinen Seelenspiegel vor
          Diebstahl und Verwitterung zu schützen?
        </p>
        <div class="space-y-3">
          <button
            class="w-full primary-button"
            @click="checkoutStore.onCloseConfirmUpsellsDialog"
          >
            Seelenspiegel absichern
          </button>
          <button
            class="w-full secondary-button"
            @click="
              () => {
                checkoutStore.onContinueWithoutUpsells();
                checkoutStore.onNext(applyToPricing, applyToUpsells, code);
              }
            "
          >
            Ohne fortfahren
          </button>
        </div>
      </div>
    </CustomDialog>
  </div>
</template>

<style>
.pricing-el,
.upsells-el {
  position: absolute;
  width: 100%;
  height: calc(100svh - 91px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 20px;
  overflow-y: auto;
}
</style>
