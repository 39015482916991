<script setup lang="ts">
const navigationMenuStore = useNavigationMenuStore();
const { user, isAuthenticated, logout } = useAuth();

const closeDrawer = () => {
  navigationMenuStore.toggleMenu();
};

const route = useRoute();
const router = useRouter();

const onLogout = () => {
  logout();
  closeDrawer();
  router.push("/");
};

const { Texts } = useTexts();
const { partner } = useTheme();
</script>

<template>
  <Transition name="dialog">
    <div
      v-if="navigationMenuStore.showMenu"
      class="h-screen w-screen p-5 flex flex-col fixed top-0 left-0 bg-white/40 backdrop-blur-xl z-50"
    >
      <div class="flex flex-row items-center justify-between">
        <button
          class="flex justify-start"
          @click="navigationMenuStore.toggleMenu()"
        >
          <icon name="eva:close-fill" size="26px" class="text-black" />
        </button>

        <nuxt-link to="/" class="w-fit" @click="closeDrawer">
          <nuxt-img
            v-if="partner"
            :src="partner.logoUrl"
            :alt="Texts.OfficialName"
            :style="`height: ${partner.logoHeightSm}px; width: auto;`"
          />
          <p
            v-else
            class="font-body text-center font-bold uppercase text-[3.5vw] sm:text-xl"
          >
            {{ Texts.OfficialName }}
          </p>
        </nuxt-link>

        <nuxt-link
          v-if="isAuthenticated"
          class="flex justify-end"
          to="/mein-konto"
          @click="closeDrawer"
        >
          <icon name="line-md:account" size="26px" class="text-black" />
        </nuxt-link>
        <div v-else class="w-[26px] h-[26px]" />
      </div>

      <div
        class="flex flex-col space-y-6 mt-14 text-3xl font-title text-black/80"
      >
        <nuxt-link
          v-for="item in navigationMenuStore.menuItems"
          :key="item.title"
          :to="item.link"
          :class="{
            'font-black text-black': route.path === item.link,
          }"
          @click="closeDrawer"
        >
          {{ item.title }}
        </nuxt-link>

        <div
          class="flex flex-col space-y-3 text-lg font-body text-black/80 font-light"
        >
          <button
            v-if="isAuthenticated && user?.id"
            class="text-red-800 w-min font-semibold mb-3"
            @click="onLogout"
          >
            Abmelden
          </button>
          <nuxt-link
            v-for="item in navigationMenuStore.legalItems"
            :key="item.title"
            :to="item.link"
            :class="{
              'font-semibold text-black': route.path === item.link,
            }"
            @click="closeDrawer"
          >
            {{ item.title }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </Transition>
</template>
