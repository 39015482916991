import type { Partner } from "~/db/schema";

export const useTheme = () => {
  const isInitialized = useState("isInitialized", () => false);
  const { subdomain } = useTexts();

  const primaryColor = useState("primaryColor", () => "241, 203, 102");
  const fontBase64 = useState<string | null>("fontBase64", () => null);
  const partner = useState<Partner | null>("partner", () => null);

  const initializeTheme = async () => {
    try {
      partner.value = await $fetch(`/api/partners/${subdomain}`);
    } catch (error: any) {
      const fontFace = `
      @font-face {
        font-family: 'dynamic-font';
        src: url('/fonts/Merriweather-Light.ttf') format('truetype');
        font-weight: 300;
      }
      @font-face {
        font-family: 'dynamic-font';
        src: url('/fonts/Merriweather-Regular.ttf') format('truetype');
        font-weight: normal;
      }
      @font-face {
        font-family: 'dynamic-font';
        src: url('/fonts/Merriweather-Bold.ttf') format('truetype');
        font-weight: bold;
      }
      @font-face {
        font-family: 'dynamic-font';
        src: url('/fonts/Merriweather-Black.ttf') format('truetype');
        font-weight: 900;
      }
      `;
      const styleSheet = document.createElement("style");
      styleSheet.innerText = fontFace;
      document.head.appendChild(styleSheet);

      isInitialized.value = true;
      return;
    }

    primaryColor.value = partner.value!.primaryColor;
    fontBase64.value = partner.value!.fontBase64;

    document.documentElement.style.setProperty(
      "--primary-color",
      primaryColor.value,
    );

    const fontFace = `@font-face {
      font-family: 'dynamic-font';
      src: url(data:font/truetype;charset=utf-8;base64,${fontBase64.value}) format('truetype');
      font-weight: normal;
      font-style: normal;
    }`;
    const styleSheet = document.createElement("style");
    styleSheet.innerText = fontFace;
    document.head.appendChild(styleSheet);

    isInitialized.value = true;
  };

  return { initializeTheme, isInitialized, partner };
};
