export const useTexts = () => {
  let origin: string | undefined;

  try {
    const url = useRequestURL();
    origin = url.origin;
  } catch (error: any) {
    throw new Error("Origin not found: " + error.message);
  }

  if (!origin) {
    throw new Error("Origin not found");
  }

  const rawSubdomain = origin.split(".")[0].split("//")[1];

  const subdomain = origin.includes("https://app.memoratech.com")
    ? "memoratech"
    : origin.includes("https://app.meinseelenspiegel.de")
      ? "seelenspiegel"
      : rawSubdomain
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
          .toLowerCase();

  const officialName = useState<string>("officialName", () => subdomain);

  const v = origin.includes("app.meinseelenspiegel.de") ? "C" : "B";

  const defaultTexts = {
    C: {
      baseUrl: "https://app.meinseelenspiegel.de",
      isC: true,
      OfficialName: "Seelenspiegel",
      Config: {
        AppTitle: "Seelenspiegel - App",
        AppDescription: "Verewige deine Liebsten ❤️",
      },
      Errors: {
        AlreadyClaimed:
          "Der Seelenspiegel wurde bereits eingelöst, fragen Sie den Besitzer des Seelenspiegels nach einer Einladung",
      },
      Contact: {
        Email: "info@meinseelenspiegel.de",
        Phone: "017645673876",
      },
    },
    B: {
      baseUrl: `https://${rawSubdomain.toLowerCase()}.memoratech.com`,
      isC: false,
      OfficialName: officialName.value,
      Config: {
        AppTitle: `${officialName.value} - Gedenkseite`,
        AppDescription: `${officialName.value} - Halten Sie die Erinnerungen Ihrer Liebsten am Leben`,
      },
      Errors: {
        AlreadyClaimed:
          "Diese Gedenkseite ist bereits vergeben, fragen Sie den Besitzer der Gedenkseite nach einer Einladung",
      },
      Contact: {
        Email: "info@memoratech.com",
        Phone: "017645673876",
      },
    },
  };

  const Texts = useState("Texts", () => defaultTexts[v]);

  const setOfficialName = (name: string) => {
    officialName.value = name;
    Texts.value.OfficialName = name;
    Texts.value.Config.AppTitle = `${name} - Gedenkseite`;
    Texts.value.Config.AppDescription = `${name} - Halten Sie die Erinnerungen Ihrer Liebsten am Leben`;
  };

  return { Texts, setOfficialName, subdomain };
};

export const useTextsServer = (customOrigin?: string) => {
  let origin = customOrigin;

  if (!origin) {
    try {
      // Assuming useRequestURL is a custom function that retrieves the URL of the request
      const url = useRequestURL(); // Ensure this is adapted for non-React environments
      origin = url.origin;
    } catch (error: any) {
      throw new Error("Origin not found: " + error.message);
    }
  }

  if (!origin) {
    throw new Error("Origin not found");
  }

  const rawSubdomain = origin.split(".")[0].split("//")[1];

  const subdomain = origin.includes("https://app.memoratech.com")
    ? "memoratech"
    : rawSubdomain
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .toLowerCase();

  let officialName = subdomain; // Simplified state management

  const v = origin.includes("app.meinseelenspiegel.de") ? "C" : "B";

  const defaultTexts = {
    C: {
      baseUrl: "https://app.meinseelenspiegel.de",
      isC: true,
      OfficialName: "Seelenspiegel",
      Config: {
        AppTitle: "Seelenspiegel - App",
        AppDescription: "Verewige deine Liebsten ❤️",
      },
      Errors: {
        AlreadyClaimed:
          "Der Seelenspiegel wurde bereits eingelöst, fragen Sie den Besitzer des Seelenspiegels nach einer Einladung",
      },
      Contact: {
        Email: "info@meinseelenspiegel.de",
        Phone: "017645673876",
      },
    },
    B: {
      baseUrl: `https://${rawSubdomain.toLowerCase()}.memoratech.com`,
      isC: false,
      OfficialName: officialName,
      Config: {
        AppTitle: `${officialName} - Gedenkseite`,
        AppDescription: `${officialName} - Halten Sie die Erinnerungen Ihrer Liebsten am Leben`,
      },
      Errors: {
        AlreadyClaimed:
          "Diese Gedenkseite ist bereits vergeben, fragen Sie den Besitzer der Gedenkseite nach einer Einladung",
      },
      Contact: {
        Email: "info@memoratech.com",
        Phone: "017645673876",
      },
    },
  };

  const Texts = defaultTexts[v]; // Initialize Texts based on 'v'

  const setOfficialName = (name: string) => {
    officialName = name; // Update the internal state
    Texts.OfficialName = name;
    Texts.Config.AppTitle = `${name} - Gedenkseite`;
    Texts.Config.AppDescription = `${name} - Halten Sie die Erinnerungen Ihrer Liebsten am Leben`;
  };

  return { Texts, setOfficialName, subdomain };
};
