<script setup lang="ts">
const appBarStore = useAppBarStore();
const navigationMenuStore = useNavigationMenuStore();

const { isAuthenticated } = useAuth();

const route = useRoute();

const { Texts } = useTexts();
const { partner } = useTheme();
</script>

<template>
  <div
    :class="[
      'w-full md:h-24 py-5 px-5 md:px-10 flex flex-row items-center justify-between absolute top-0 left-0 z-40',
      appBarStore.elevated === true
        ? 'bg-white/70 border-b border-black/10'
        : 'bg-transparent md:bg-white/70 md:border-b md:border-black/10',
    ]"
  >
    <div class="w-full hidden md:flex">
      <div class="flex flex-row items-center space-x-10">
        <nuxt-link to="/" class="flex justify-center w-full">
          <nuxt-img
            v-if="partner"
            :src="partner.logoUrl"
            :alt="Texts.OfficialName"
            :style="`height: ${partner.logoHeightLg}px; width: auto;`"
          />
          <p
            v-else
            class="font-body text-xl font-bold uppercase"
            style="text-shadow: 0 0 10px rgba(0, 0, 0, 0.1)"
          >
            {{ Texts.OfficialName }}
          </p>
        </nuxt-link>
        <DesktopNavigationMenu />
      </div>
      <nuxt-link
        v-if="isAuthenticated && route.path !== '/mein-konto'"
        to="/mein-konto"
        class="flex justify-end ml-auto"
      >
        <icon name="line-md:account" size="26px" class="text-black" />
      </nuxt-link>
    </div>
    <div class="flex flex-row items-center justify-between w-full md:hidden">
      <button
        class="flex md:hidden justify-start"
        @click="navigationMenuStore.toggleMenu()"
      >
        <icon name="iconoir:menu-scale" size="26px" class="text-black" />
      </button>
      <nuxt-link to="/" class="flex justify-center w-fit">
        <nuxt-img
          v-if="partner"
          :src="partner.logoUrl"
          :alt="Texts.OfficialName"
          :style="`height: ${partner.logoHeightSm}px; width: auto;`"
        />
        <p
          v-else
          class="font-body text-center font-bold uppercase text-base sm:text-xl"
          style="
            /* font-size: max(3vw, 17px); */
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          "
        >
          {{ Texts.OfficialName }}
        </p>
      </nuxt-link>
      <nuxt-link
        v-if="isAuthenticated && route.path !== '/mein-konto'"
        to="/mein-konto"
        class="flex justify-end"
      >
        <icon name="line-md:account" size="26px" class="text-black" />
      </nuxt-link>
      <div v-else class="w-[30px] h-[26px]" />
    </div>
  </div>
</template>
