export default defineAppConfig({
  nuxtIcon: {
    size: "24px", // default <Icon> size applied
    class: "icon", // default <Icon> class applied
  },
  umami:
    process.env.NODE_ENV === "production"
      ? {
          host: process.env.NUXT_PUBLIC_UMAMI_HOST,
          id: process.env.NUXT_PUBLIC_UMAMI_ID,
          autoTrack: true,
          useDirective: true,
          version: 2,
        }
      : undefined,
});
