<script setup lang="ts">
const checkoutStore = useCheckoutStore();
</script>

<template>
  <div class="space-y-10 mb-20">
    <div>
      <p class="text-3xl font-title font-bold my-4 text-center">
        Bonusangebote
      </p>

      <p class="text-black/70 text-center mt-5 text-sm">
        Füge Extras hinzu, um deinen Seelenspiegel vor Diebstahl und
        Verwitterung zu schützen oder um zusätzlichen Speicherplatz zu erhalten.
      </p>
    </div>

    <div
      class="w-full h-fit flex flex-col items-center justify-center space-y-5"
    >
      <div
        v-for="upsell in checkoutStore.upsells"
        :key="upsell.id"
        class="flex flex-col space-y-4 p-4 border border-primary rounded bg-gradient-to-br from-primary/5 to-primary/20 max-w-lg"
      >
        <div class="space-x-3 flex flex-row items-center justify-center">
          <img
            :src="upsell.image"
            :alt="upsell.name"
            class="h-16 w-16 object-cover"
          />
          <div class="flex flex-col space-y-1">
            <p class="font-medium text-base text-black">
              {{ upsell.description }}
            </p>
            <div
              class="w-fit flex flex-row items-center justify-center space-x-2.5 text-base"
            >
              <p class="font-bold">
                {{ upsell.price }}
              </p>
              <p class="text-red-700 line-through font-bold">
                {{ upsell.usualPrice }}
              </p>
            </div>
          </div>
        </div>

        <button
          :class="[
            'w-full px-8 py-2.5 rounded-md bg-primary/60 border-[1.5px] border-primary font-bold active:scale-105 transition-all duration-150 shadow-xl shadow-primary/40',
            checkoutStore.selectedUpsells[upsell.id] === true
              ? 'grayscale'
              : '',
          ]"
          @click="checkoutStore.onAddUpsell(upsell.id)"
        >
          {{
            checkoutStore.selectedUpsells[upsell.id] === true
              ? "Entfernen"
              : "Hinzufügen"
          }}
        </button>
      </div>
    </div>
  </div>
</template>
