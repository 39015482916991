import { loadStripe, type Stripe } from "@stripe/stripe-js";

export const useCheckoutStore = defineStore("checkout", () => {
  // const config = useRuntimeConfig();

  type CheckoutSteps = "pricing" | "upsells";

  const showPopup = useState("checkout-showPopup", () => false);

  const openPopup = () => {
    showPopup.value = true;
  };

  const onClosePopup = () => {
    showPopup.value = false;
    // reset state
    selectedOption.value = "15years";
    selectedUpsells.value = {
      insurance: false,
      extraStorage: false,
    };
    noUpsellsNeeded.value = false;
    currentStep.value = "pricing";
  };

  const onPrevious = async (applyToPricing: any, applyToUpsells: any) => {
    if (currentStep.value === "upsells") {
      currentStep.value = "pricing";
      await applyToUpsells({
        scale: 0.95,
        opacity: 0,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 100,
        },
      });
      await applyToPricing({
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 65,
        },
      });
    }
  };

  const selectedOption = useState<PricingOption>(
    "checkout-selectedOption",
    () => "15years",
  );
  const selectedUpsells = useState("checkout-selectedUpsells", () => ({
    insurance: false,
    extraStorage: false,
  }));

  const anyUpsellsSelected = computed(() => {
    return (
      selectedUpsells.value.insurance === true ||
      selectedUpsells.value.extraStorage === true
    );
  });

  const noUpsellsNeeded = useState("checkout-noUpsellsNeeded", () => false);
  const currentStep = useState<CheckoutSteps>(
    "checkout-currentStep",
    () => "pricing",
  );
  const isLoadingStripe = useState("checkout-isLoadingStripe", () => false);
  const showConfirmUpsellsDialog = useState(
    "checkout-showConfirmUpsellsDialog",
    () => false,
  );
  const stripe = useState<Stripe | null>("checkout-stripe", () => null);

  const loadStripeInstance = async () => {
    stripe.value = await loadStripe(
      "pk_live_51PFl5306kXbAMPAxNFfA0hv7lUMM9mCHe6QL6En4NZDtI6rO3keuCRfP01H9688Qi0G0ROlpDqTTEdmo0V4t2gSK0081TmpZVk",
      // config.public.stripePublicKey as string
    );
  };

  const selectOption = (option: PricingOption) => {
    selectedOption.value = option;
  };
  const onNext = async (
    applyToPricing: any,
    applyToUpsells: any,
    code: string,
  ) => {
    if (currentStep.value === "pricing") {
      currentStep.value = "upsells";
      await applyToPricing({
        scale: 0.95,
        opacity: 0,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 100,
        },
      });
      await applyToUpsells({
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 65,
        },
      });
      return;
    }
    if (currentStep.value === "upsells") {
      // If no upsells are selected and the user has not confirmed that they do not
      // want any upsells yet, show the confirm dialog instead of proceeding to checkout
      if (
        selectedUpsells.value.insurance === false &&
        selectedUpsells.value.extraStorage === false &&
        noUpsellsNeeded.value === false
      ) {
        showConfirmUpsellsDialog.value = true;
        return;
      }

      await applyToUpsells({
        scale: 0.95,
        opacity: 0,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 100,
        },
      });

      isLoadingStripe.value = true;

      const res = await $fetch("/api/stripe/create-payment-intent", {
        method: "POST",
        body: JSON.stringify({
          plan: selectedOption.value,
          upsells: selectedUpsells.value,
          code,
        }),
      });

      if (res) {
        stripe.value?.redirectToCheckout({
          sessionId: res.toString(),
        });
        isLoadingStripe.value = false;
      } else {
        isLoadingStripe.value = false;
        alert("Bitte versuchen Sie es erneut.");
      }
    }
  };

  const setCheckoutStep = (
    step: CheckoutSteps,
    applyToPricing: any,
    applyToUpsells: any,
  ) => {
    currentStep.value = step;
    if (step === "upsells") {
      applyToPricing({
        scale: 0.95,
        opacity: 0,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 100,
        },
      });
      applyToUpsells({
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          damping: 10,
          stiffness: 65,
        },
      });
    }
  };

  const onAddUpsell = (upsell: "insurance" | "extraStorage") => {
    selectedUpsells.value = {
      ...selectedUpsells.value,
      [upsell]: !selectedUpsells.value[upsell],
    };
  };

  const onCloseConfirmUpsellsDialog = () => {
    showConfirmUpsellsDialog.value = false;
  };

  const onContinueWithoutUpsells = () => {
    noUpsellsNeeded.value = true;
    showConfirmUpsellsDialog.value = false;
  };

  const plans: {
    [key in PricingOption]: {
      name: string;
      price: string;
      usualPrice: string;
      description: string;
    };
  } = {
    "1year": {
      name: "1 Jahr",
      price: "€29,45",
      usualPrice: "€39,95",
      description: "1 Jahr Seelenspiegel Nutzung",
    },
    "10years": {
      name: "10 Jahre",
      price: "€79,95",
      usualPrice: "€99,95",
      description: "10 Jahre Seelenspiegel Nutzung",
    },
    "15years": {
      name: "15 Jahre",
      price: "€99,95",
      usualPrice: "€142,79",
      description: "15 Jahre Seelenspiegel Nutzung",
    },
    "25years": {
      name: "25 Jahre",
      price: "119,95",
      usualPrice: "€199,95",
      description: "25 Jahre Seelenspiegel Nutzung",
    },
    subscription: {
      name: "Abonnoment",
      price: "€3,95",
      usualPrice: "€5,95",
      description: "Monatliche Seelenspiegel Nutzung",
    },
  };

  const upsells: {
    id: "insurance" | "extraStorage";
    name: string;
    description: string;
    price: string;
    usualPrice: string;
    image: string;
  }[] = [
    {
      id: "insurance",
      name: "Versicherung",
      description:
        "Schütze deinen Seelenspiegel vor Diebstahl und Verwitterung",
      price: "€9,99",
      usualPrice: "€19,99",
      image:
        "https://checkout.meinseelenspiegel.de/cdn/shop/files/c84d8091-8a89-4bd9-bd20-765cb8e3356f.png?v=1712411695&width=100",
    },
    {
      id: "extraStorage",
      name: "Zusätzlicher Speicherplatz",
      description: "3GB zusätzlicher Speicherplatz für deinen Seelenspiegel",
      price: "€8,99",
      usualPrice: "€24,99",
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/storage-9723795-7887227.png",
    },
  ];

  const totalPrice = computed(() => {
    const price = plans[selectedOption.value].price
      .replace("€", "")
      .replace(",", ".");
    const upsellPrices = upsells
      .filter((u) => selectedUpsells.value[u.id])
      .map((u) => u.price.replace("€", "").replace(",", "."));

    return (
      "€" +
      (
        parseFloat(price) +
        upsellPrices.reduce((acc, p) => acc + parseFloat(p), 0)
      )
        .toFixed(2)
        .replace(".", ",")
    );
  });

  return {
    showPopup,
    plans,
    selectedOption,
    selectedUpsells,
    anyUpsellsSelected,
    noUpsellsNeeded,
    currentStep,
    isLoadingStripe,
    showConfirmUpsellsDialog,
    upsells,
    totalPrice,

    openPopup,
    onClosePopup,
    onPrevious,
    loadStripeInstance,
    onNext,
    onAddUpsell,
    onCloseConfirmUpsellsDialog,
    onContinueWithoutUpsells,
    selectOption,
    setCheckoutStep,
  };
});
