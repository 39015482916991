import { default as eventsXLVRVlBuumMeta } from "/app/pages/admin/events.vue?macro=true";
import { default as trialsEsH0MmTmukMeta } from "/app/pages/admin/trials.vue?macro=true";
import { default as agbsWsuWhaiHenMeta } from "/app/pages/agbs.vue?macro=true";
import { default as indexvgJWyVGS5sMeta } from "/app/pages/benutzer/[id]/index.vue?macro=true";
import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as gedenkseitenD7GClqfFO1Meta } from "/app/pages/gedenkseiten.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as kontaktzJWjXi4cLvMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as mein_45kontobBlPnCemjkMeta } from "/app/pages/mein-konto.vue?macro=true";
import { default as dashboardr7uaYd03vdMeta } from "/app/pages/partner/dashboard.vue?macro=true";
import { default as bearbeitenOYMMwLuc3IMeta } from "/app/pages/u/[code]/bearbeiten.vue?macro=true";
import { default as indexFra9TO1mpmMeta } from "/app/pages/u/[code]/index.vue?macro=true";
export default [
  {
    name: eventsXLVRVlBuumMeta?.name ?? "admin-events",
    path: eventsXLVRVlBuumMeta?.path ?? "/admin/events",
    meta: eventsXLVRVlBuumMeta || {},
    alias: eventsXLVRVlBuumMeta?.alias || [],
    redirect: eventsXLVRVlBuumMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/events.vue").then(m => m.default || m)
  },
  {
    name: trialsEsH0MmTmukMeta?.name ?? "admin-trials",
    path: trialsEsH0MmTmukMeta?.path ?? "/admin/trials",
    meta: trialsEsH0MmTmukMeta || {},
    alias: trialsEsH0MmTmukMeta?.alias || [],
    redirect: trialsEsH0MmTmukMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/trials.vue").then(m => m.default || m)
  },
  {
    name: agbsWsuWhaiHenMeta?.name ?? "agbs",
    path: agbsWsuWhaiHenMeta?.path ?? "/agbs",
    meta: agbsWsuWhaiHenMeta || {},
    alias: agbsWsuWhaiHenMeta?.alias || [],
    redirect: agbsWsuWhaiHenMeta?.redirect || undefined,
    component: () => import("/app/pages/agbs.vue").then(m => m.default || m)
  },
  {
    name: indexvgJWyVGS5sMeta?.name ?? "benutzer-id",
    path: indexvgJWyVGS5sMeta?.path ?? "/benutzer/:id()",
    meta: indexvgJWyVGS5sMeta || {},
    alias: indexvgJWyVGS5sMeta?.alias || [],
    redirect: indexvgJWyVGS5sMeta?.redirect || undefined,
    component: () => import("/app/pages/benutzer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: datenschutz8Idu2tRmArMeta?.name ?? "datenschutz",
    path: datenschutz8Idu2tRmArMeta?.path ?? "/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    alias: datenschutz8Idu2tRmArMeta?.alias || [],
    redirect: datenschutz8Idu2tRmArMeta?.redirect || undefined,
    component: () => import("/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: gedenkseitenD7GClqfFO1Meta?.name ?? "gedenkseiten",
    path: gedenkseitenD7GClqfFO1Meta?.path ?? "/gedenkseiten",
    meta: gedenkseitenD7GClqfFO1Meta || {},
    alias: gedenkseitenD7GClqfFO1Meta?.alias || [],
    redirect: gedenkseitenD7GClqfFO1Meta?.redirect || undefined,
    component: () => import("/app/pages/gedenkseiten.vue").then(m => m.default || m)
  },
  {
    name: impressumLDkFRkkSAmMeta?.name ?? "impressum",
    path: impressumLDkFRkkSAmMeta?.path ?? "/impressum",
    meta: impressumLDkFRkkSAmMeta || {},
    alias: impressumLDkFRkkSAmMeta?.alias || [],
    redirect: impressumLDkFRkkSAmMeta?.redirect || undefined,
    component: () => import("/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktzJWjXi4cLvMeta?.name ?? "kontakt",
    path: kontaktzJWjXi4cLvMeta?.path ?? "/kontakt",
    meta: kontaktzJWjXi4cLvMeta || {},
    alias: kontaktzJWjXi4cLvMeta?.alias || [],
    redirect: kontaktzJWjXi4cLvMeta?.redirect || undefined,
    component: () => import("/app/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: mein_45kontobBlPnCemjkMeta?.name ?? "mein-konto",
    path: mein_45kontobBlPnCemjkMeta?.path ?? "/mein-konto",
    meta: mein_45kontobBlPnCemjkMeta || {},
    alias: mein_45kontobBlPnCemjkMeta?.alias || [],
    redirect: mein_45kontobBlPnCemjkMeta?.redirect || undefined,
    component: () => import("/app/pages/mein-konto.vue").then(m => m.default || m)
  },
  {
    name: dashboardr7uaYd03vdMeta?.name ?? "partner-dashboard",
    path: dashboardr7uaYd03vdMeta?.path ?? "/partner/dashboard",
    meta: dashboardr7uaYd03vdMeta || {},
    alias: dashboardr7uaYd03vdMeta?.alias || [],
    redirect: dashboardr7uaYd03vdMeta?.redirect || undefined,
    component: () => import("/app/pages/partner/dashboard.vue").then(m => m.default || m)
  },
  {
    name: bearbeitenOYMMwLuc3IMeta?.name ?? "u-code-bearbeiten",
    path: bearbeitenOYMMwLuc3IMeta?.path ?? "/u/:code()/bearbeiten",
    meta: bearbeitenOYMMwLuc3IMeta || {},
    alias: bearbeitenOYMMwLuc3IMeta?.alias || [],
    redirect: bearbeitenOYMMwLuc3IMeta?.redirect || undefined,
    component: () => import("/app/pages/u/[code]/bearbeiten.vue").then(m => m.default || m)
  },
  {
    name: indexFra9TO1mpmMeta?.name ?? "u-code",
    path: indexFra9TO1mpmMeta?.path ?? "/u/:code()",
    meta: indexFra9TO1mpmMeta || {},
    alias: indexFra9TO1mpmMeta?.alias || [],
    redirect: indexFra9TO1mpmMeta?.redirect || undefined,
    component: () => import("/app/pages/u/[code]/index.vue").then(m => m.default || m)
  }
]