import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const router = useRouter();
  const sentry = useRuntimeConfig().public.sentry as any;
  if (!sentry.dsn) {
    return;
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          "https://app.meinseelenspiegel.de/api/",
          "https://*.memoratech.com/api/",
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.2, // Capture 10% of the sessions
    replaysOnErrorSampleRate: 0.5, // Capture 50% of errors
  });
});
