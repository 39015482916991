import type { Code, User } from "~/db/schema";

export const useAuth = () => {
  type ExtendedUser = User & { codes: Code[] };

  const user = useState<ExtendedUser | null>("useAuthUser", () => null);

  // Fetch the user on initial load only once
  const initialize = () => {
    $fetch("/api/user", {
      method: "GET",
      onResponse: ({ response }) => (user.value = response._data),
    });

    $fetch("/api/auth/logout-if-invalid", { method: "GET" });
  };

  const cookie = useCookie("jwt", {
    path: "/",
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    maxAge: 60 * 60 * 24 * 60, // 60 days
    watch: "shallow",
  });

  watch(cookie, async (value) => {
    if (value && value.length > 0) {
      await $fetch("/api/user").then((res) => (user.value = res));
    }
  });

  const isAuthenticated = computed(() => !!user.value);

  const login = async (email: string, code: string | null) => {
    await $fetch("/api/auth/send-magic-link", {
      method: "POST",
      body: { email, code },
    }).catch((error) => {
      throw new Error(error.message);
    });
  };

  const logout = async () => {
    await $fetch("/api/auth/logout", {
      method: "GET",
    }).catch((error) => {
      throw new Error(error.message);
    });
    user.value = null;
  };

  return {
    user,
    isAuthenticated,
    initialize,
    login,
    logout,
  };
};
